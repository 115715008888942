<template>
  <div class="data-box">
    <van-nav-bar
        title="物资发放"
        left-text="返回"
        :right-text="showStatus == 1 ? '回收物资' : ''"
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
        @click-right="toMaterialRecycling"
    />
    <div class="data-box-head">
      活动期间请注意各类物资的节约使用
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <div class="span-key">领用人</div>
          <div class="span-input" @click="editMaterialInfo">
            <span>{{materialInfo.designation ? materialInfo.designation : '请选择领用人'}}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">领用周期</div>
          <div class="span-value">{{ materialInfo.receiveDate ? materialInfo.receiveDate : "无" }}</div>
        </div>
      </div>
      <div v-show="showStatus == 1 && materialList.length > 0">
        <div class="body-div">
          <div class="body-div-note">
            带*标记物资为需交回物资
          </div>
          <div class="body-div-table">
            <dl>
              <tr>
                <dt>物品名称</dt>
                <dt>剩余数量</dt>
                <dt>单次限领</dt>
                <dt>本次发放</dt>
              </tr>
              <tr v-for="(item,index) in materialList" :key="index">
                <dd style="color: #3476FF;" @click="toMaterialDetails(item)"><span style="color: #FA5555;" v-show="item.num3">*</span>{{item.str}}</dd>
                <dd>{{item.num1}}</dd>
                <dd>{{item.num2}}</dd>
                <dd><input class="van-cell" type="number" v-model="item.num4" placeholder="请输入数量" maxlength="8" @input="inputHandler(item,index)"/></dd>
              </tr>
            </dl>
          </div>
        </div>
        <div class="body-div">
          <div class="body-div-item" style="align-items: flex-start;">
            <div class="span-key span-top">物资图片</div>
            <div class="span-value">
              <van-uploader name="material" v-model="imageList" :after-read="afterRead" max-count="9" accept="image/*" multiple>
                <template>
                  <div class="upload-div">
                    <i class="iconfont icon-plus-round"></i>
                  </div>
                </template>
              </van-uploader>
            </div>
          </div>
          <div class="body-div-prompt">
            温馨提示：请上传领取人与所领取物资合影照片
          </div>
        </div>
        <div class="data-box-foot">
          <van-button class="btn" round block type="info" @click="onClickRight">查看已发放</van-button>
          <van-button class="btn" round block type="primary" @click="onSure">保存</van-button>
        </div>
      </div>
      <div v-show="showStatus == 2 && materialList.length > 0">
        <div class="body-div" >
          <div class="body-div-title align-left">
            需回收物资<br/><span style="color: #FA5555;">(请注意证件安全与相关单据完整)</span>
          </div>
          <div class="body-div-item" v-for="(item,index) in materialList" :key="index" @click="toMaterialDetails(item)" v-show="item.num3">
            <span class="span-key">{{ item.str }}</span>
            <span class="span-value align-right">
              <i class="iconfont icon-iconfontjiantou2" style="color: #3E4576;"></i>
            </span>
          </div>
        </div>
        <div class="body-div">
          <div class="body-div-title align-left">
            损耗类物资&nbsp;<span>(无需回收)</span>
          </div>
          <div class="body-div-item" v-for="(item,index) in materialList" :key="index" v-show="!item.num3">
            <span class="span-key">{{ item.str }}</span>
            <span class="span-value align-right">{{ item.sendNum + item.str1 }}</span>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet v-model="showMaterialPerson" :actions="materialPersonActions" :description="materialPersonActions.length > 0 ? '' : '暂无数据'" @select="selectMaterialInfo"></van-action-sheet>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import upload from "@/assets/common/upload";
import {
  canSelectStayFoodGrantPersons,
  showActivityMaterial,
  grantMaterial
} from "@/api/index";

export default {
  name: "orgMaterial",
  data() {
    return {
      showStatus: 1,
      showMaterialPerson: false,
      materialPersonActions: [],
      materialList: [],
      materialInfo: {
        designation: "",
        receiveDate: ""
      },
      imageList: []
    };
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.getActiveObject();
      that.showStatus = that.$store.getters.getMaterialStatus ? that.$store.getters.getMaterialStatus : 1;
      if(that.$store.getters.getSelectMaterialPersonId){
        that.getMaterialInfo(that.$store.getters.getSelectMaterialPersonId);
      }
    },
    //获取活动对象
    getActiveObject(){
      let that = this;
      let params = {
        actId: that.$store.getters.getActivityId,
        activityIds: [0,1],
        pageNum: 1,
        pageSize: 9999,
        type: 1
      };
      canSelectStayFoodGrantPersons(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.materialPersonActions = res.data.records;
          that.materialPersonActions.forEach(item => {
            item.name = item.designation;
          })
        })
      })
    },
    getMaterialInfo(id){
      let that = this;
      let params = {
        ayId : id
      };
      showActivityMaterial(params).then(res => {
        that.$tool.resValidation(res, () => {
          that.materialInfo = res.data;
          that.materialList = that.materialInfo.materialList ? that.materialInfo.materialList : [];
          that.materialList.forEach(item => {
            item.sendNum = Math.abs(item.num4);
            item.num4 = null;
          })
        })
      })
    },
    onClickLeft() {
      let that = this;
      if(that.showStatus === 2){
        that.showStatus = 1;
        that.$store.commit('setMaterialStatus', 1);
      }else{
        that.$router.go(-1);
      }
    },
    onClickRight(){
      let that = this;
      that.showStatus = 2;
      that.$store.commit('setMaterialStatus', 2);
    },
    editMaterialInfo(){
      let that = this;
      that.showMaterialPerson = true;
    },
    selectMaterialInfo(item){
      let that = this;
      that.$store.commit('setSelectMaterialPersonId', item.id);
      that.getMaterialInfo(item.id);
      that.showMaterialPerson = false;
    },
    afterRead(file,detail){
      let that = this;
      upload.uploadFile(file,res => {
        if(detail.name === "material"){
          that.imageList[res.index].url = res.url;
        }
      },err => {
        console.log(err);
      });
    },
    //本次发放输入验证
    inputHandler(item,index){
      let that = this;
      item.num4 = Number(item.num4) > item.num2 ? item.num2 : Number(item.num4);
      this.$set(that.materialList, index, item);
    },
    onCancel(){
      this.$router.go(-1);
    },
    onSure(){
      let that = this;
      that.materialList.forEach(item => {
        item.num4 = item.num4 ? item.num4 : 0;
      })
      if(that.imageList.length === 0){
        that.$toast('请上传物资图片！');
        return;
      }
      let array = [];
      let images = [];
      that.imageList.forEach(item => {
        images.push(item.url);
      })
      that.materialList.forEach(item => {
        let obj = {
          "activityId": that.$store.getters.getSelectMaterialPersonId,
          "materialType": item.id,
          "num": -item.num4,
          "picture": images.join(","),
          "type": 0
        };
        array.push(obj);
      })
      grantMaterial(array).then(res => {
        that.$tool.resValidation(res, () => {
          that.getMaterialInfo(that.$store.getters.getSelectMaterialPersonId);
          that.$toast.success('物资发放成功！');
        })
      })
    },
    toMaterialDetails(item){
      let that = this;
      that.$router.push({
        name: 'orgCheck_material_details',
        query: {
          id: item.id
        }
      });
    },
    toMaterialRecycling(){
      let that = this;
      that.$router.push("orgMaterial_recycling");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.upload-div{
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  background-color: #F6F9FF;
  border-radius: 10px;
  text-align: center;
  i{
    color: #D6E3FE;
    opacity: 0.5;
  }
}
::v-deep .van-uploader__preview {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  .van-uploader__preview-image {
    display: block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    img{
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }
  }
}
.van-cell{
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 10px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  border-bottom-right-radius: 10px;
  border: 0px;
}
</style>